/* @import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200..800&display=swap') */

.aboutContainer{
    position: relative;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    background-color: transparent;
    margin-top: -4rem;
}
.profileImg{
    position:relative;
    width:30%;
    min-width:370px ;
    background-color: transparent;
    display: flex;
    align-items: center;
    margin-right: 7rem;
}
.profileImg img{
    width:100%;
    height:auto;
    object-fit: cover;
    display: block;
    background-color: transparent;
    z-index: 2;
}
.bgBorder{
    position:absolute;
    border-left: 8px solid rgb(255, 120, 36);
    border-top: 8px solid rgb(255, 120, 36);
    border-bottom: 8px solid rgb(255, 120, 36);
    top:50%;
    right:1%;
    width: 120%;
    height: 40%;
    background: transparent;
    z-index: 1;
    animation: animateBorder 2s ease-in-out;
}
@keyframes animateBorder {
    from {
      height: 0%;
      width:0%
    }
    to {
      height: 40%;
      width:120%; 
    }
  }

.content{
    position: relative;
    color: white;
    margin-top:8rem;
    z-index: 3;
    width: 42%;
    background-color: transparent;
    margin-left: 7rem;
    font-family: "Oxanium", sans-serif;
}
@media only screen and (max-width: 768px) {
    .bgBorder{
        width:100%;
        right:-5%;
         animation: animateBorder 2s ease-in-out;
    }
    @keyframes animateBorder {
        from {
          height: 0%;
          width:0%
        }
        to {
          height: 40%;
          width:100%; 
        }
      }
    .aboutContainer{
        flex-direction: column;
        justify-content: center;
        height: auto;
        padding-top: 8rem;
        margin-top: 0rem 
    }
    .profileImg{
        order: 1;
        margin-right: 0rem;
        top:-13rem;
    }
    .profileImg Img{
        margin-left: 1.5rem;
    }
    .content{
        order:2;
        margin-left: 0rem;
        width:85%;
        margin-top:-8rem;
    }
}
.heyThere{
    font-size: 2rem;
    font-weight:600;
}
.nameLine{
    font-size:2.5rem;
    font-weight: 800;
    display:flex;
    flex-direction: row;
    column-gap: 1rem;
}
.name{
    color: rgb(255, 120, 36);
}
.passion{
    font-size: 1.2rem;
}


/* download button */
.buttonBox {
    --width: 11rem;
    --height: 2.5rem;
    --tooltip-height: 35px;
    --tooltip-width: 90px;
    --gap-between-tooltip-to-button: 18px;
    --button-color: #1163ff;
    --tooltip-color: #fff;
    width: var(--width);
    height: var(--height);
    background: var(--button-color);
    position: relative;
    text-align: center;
    border-radius: 0.45em;
    font-family: "Oxanium", sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.1rem;
    transition: background 0.3s;
    margin-top: 2rem;
}

.buttonBox::before {
    position: absolute;
    content: attr(data-tooltip);
    width: var(--tooltip-width);
    height: var(--tooltip-height);
    background-color: var(--tooltip-color);
    font-size: 0.9rem;
    color: #111;
    border-radius: .25em;
    line-height: var(--tooltip-height);
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) + 10px);
    left: calc(50% - var(--tooltip-width) / 2);
}

.buttonBox::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: var(--tooltip-color);
    left: calc(50% - 10px);
    bottom: calc(100% + var(--gap-between-tooltip-to-button) - 10px);
}

.buttonBox::after,
.buttonBox::before {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
}

.text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-wrapper,
.text,
.icon {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 120, 36);
    left: 0;
    color: #fff;
}

.text {
    top: 0
}

.text,
.icon {
    transition: top 0.5s;
}

.icon {
    color: #fff;
    top: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon svg {
    width: 24px;
    height: 24px;
    background-color: rgb(255, 120, 36);
}

.buttonBox:hover {
    background: #6c18ff;
}

.buttonBox:hover .text {
    top: -100%;
}

.buttonBox:hover .icon {
    top: 0;
}

.buttonBox:hover:before,
.buttonBox:hover:after {
    opacity: 1;
    visibility: visible;
}

.buttonBox:hover:after {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 20px);
}

.buttonBox:hover:before {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button));
}
