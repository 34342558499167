*{
    margin: 0px;
    padding: 0px;
    background: #171717;
}
.landingPageContain{
    display:flex;
    flex-direction: column;
    width:100%;
    row-gap: 10rem;
}
.sideLine{
    position:fixed;
    height:80%;
    width:2px;
    background: linear-gradient(180deg, rgba(0,36,14,0.1888357637977066) 5%, rgba(116,116,116,1) 14%, rgba(135,135,135,1) 80%, rgba(115,114,114,0.3456985088957458) 95%);
    border-image-slice: 1;
    top:50%;
    transform: translateY(-50%);
    right:2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 3.5rem;
    z-index: 10;
}
.defDiv{
    position: relative;
    color: white;
    height:8px;
    width:8px;
    border-radius: 50%;
    background-color:rgb(192, 194, 192);
}

.active{
    height:10px;
    width:10px;
    background-color:rgb(104, 242, 12);
}

.socialMedia{
    position:fixed;
    height:40%;
    width:2px;
    background:  white;
    border-image-slice: 1;
    top:55%;
    transform: translateY(-50%);
    left:2.5rem;
    display: flex;
    flex-direction: column;
    justify-content:end;
    align-items: center;
    row-gap: 1.7rem;
    z-index: 10;

}

.socialMedia div:not(:first-child) {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 2.2rem;
    width: 2.2rem;
}
.socialMedia div:not(:first-child):hover{
    border-color: blue;
}

.followMe{
    transform: rotate('90deg');
    -webkit-transform: rotate(90deg);
    color: white;
    padding-bottom: 5px;
    width:5rem;
    position: relative;
    background-color: transparent;
    top:-5rem;
}
.socialMediaMobile{
    display: none;
}
@media screen and (max-width: 600px) {
    .landingPageContain{
        row-gap: 3rem;
    }
    .sideLine{
        display: none;
    }
    .socialMedia{
        display: none;
    }
    .socialMediaMobile{
        display: flex;
        font-family: "Oxanium", sans-serif;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem;
        color: white;
        margin: 1rem;
        align-items: center;
        box-shadow: 0 4px 8px rgba(246, 246, 246, 0.2);
    }
}
.githubContainer{
    position: relative;
    display:flex;
    justify-content: center;
    width:100%;
}
.github{
    position: relative;
    width:60%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 3rem;
    row-gap: 3rem;
    justify-content: center;
    padding-bottom: 2rem;
    border-bottom: 1px dashed rgb(69, 69, 69);
}
.github img:hover{
    transform: translateY(-7px);
}



