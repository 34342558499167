.educationContainer{
    position: relative;
    color:white;
    width:99.8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 3rem;
    align-items: center;
    padding-top: 5rem;
}
.eduHeading{
    position: relative;
    width:60%;
    display: flex;
    font-weight: 800;
    font-size: 2.5em;
    font-family: "Oxanium", sans-serif;
}

.eduCard{
    position: relative;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    width:60%;
    height: auto;
}
.lineIconEdu{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.eduLine{
    position:relative;
    border:2px solid grey;
    /* height:8rem; */
    height: 100%;
    width:0px;
}
.eduContent{
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    color:white;
    row-gap: 1rem;
}
.durationEdu{
    position: relative;
    background-color: #474646;
    padding: 0.2em 1em 0em 1em ;
    border-radius: 10px;
    font-family: "Oxanium", sans-serif;
    font-size: 1.1em;
    font-weight:500;
}
.headingEdu{
    position: relative;
    font-weight: 800;
    font-family: "Oxanium", sans-serif;
    font-size: 1.4em;
}
.instituteName{
    font-family: "Oxanium", sans-serif;
    font-weight: 600;
}
.detailEdu{
    position: relative;
    font-family: "Oxanium", sans-serif;
    font-size:1em;
}
@media screen and (max-width: 600px) {
    .eduCard{
        position: relative;
        width:90%;
        column-gap: 1rem;
    }
    .eduHeading{
        width: 90%;
    }
    .educationContainer{
        padding-top: 5rem;
    }
}